import React, {useEffect} from "react";
import {Form} from "semantic-ui-react";
import store from "./VariantWidgetStore";
import {observer} from "mobx-react-lite";

function VariantWidgetPage(props: { contextKey: string }) {
    useEffect(() => {
        function handleEvent(e: any) {
            const data = e.data;
            if (!data) return;
            const eventType = data.name;
            const objectId = data.objectId;

            if (eventType === "Save") {
                void store.save();
            }

            if (eventType === "Open") {
                const helpWidget = document.getElementById("fsw-btn");
                if (helpWidget) {
                    helpWidget.style.display = "none";
                }
                void store.init(props.contextKey, objectId);
            }
        }

        window.addEventListener('message', handleEvent);
        return function cleanup() {
            window.removeEventListener('message', handleEvent);
            store.cleanup();
        }
    }, []);
    return <div>
        <Form loading={store.isLoading}>
            <Form.Input label='Номер авито'
                        value={store.avitoId}
                        type={'number'}
                        onChange={(_, data) => store.setAvitoId(Number(data.value))}/>
            <Form.TextArea style={{minHeight: '150px'}}
                           label='Ссылки на изображения'
                           value={store.imageUrls ?? ''}
                           onChange={(_, data) => store.setImageUrls(data.value as string)}/>
        </Form>
    </div>;
}

export default observer(VariantWidgetPage);