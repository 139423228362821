import api from "../Services/Api";
import {observable} from "mobx";
import {
    GetUnloadsResponse,
    UnloadSettingsDto
} from "../Entities/Dtos";
import FieldValue from "../Entities/FieldValue";
import UnloadField from "../Entities/UnloadField";
import UnloadSettingsStorage from "./UnloadSettingsStorage";
import UnloadStore from "../Entities/UnloadStore";
import storesSettingsStorage from "./StoresSettingsStorage";

class UnloadsSettingsStorage {
    @observable unloads: UnloadSettingsStorage[] = [];
    @observable fields: UnloadField[] = [];
    @observable currentUnload?: UnloadSettingsStorage;
    @observable loading: boolean = false;
    @observable isLoaded: boolean = false;
    @observable deletingUnload?: UnloadSettingsStorage;
    @observable xmlUrl: string = '';
    @observable stockUrl: string = '';

    async load() {
        this.changeLoading(true);
        if (!storesSettingsStorage.isLoaded) {
            await storesSettingsStorage.load();
        }

        try {
            const result = await api.get("settings/unloads.getSettings");
            if (result.success) {
                const response = result.data as GetUnloadsResponse;
                this.xmlUrl = response.xmlUrl;
                this.stockUrl = response.stockUrl;
                this.fields = response.fields.map(f => new UnloadField(f.id, f.name));

                this.unloads = response.unloads.map(mapUnloadSettings);

                if (this.currentUnload && !this.currentUnload.isNew) {
                    this.currentUnload = this.unloads.filter(x => x.id === this.currentUnload?.id)[0];
                    if (!this.currentUnload) {
                        this.currentUnload = this.unloads[0];
                    }
                } else {
                    this.currentUnload = this.unloads[0];
                }

                this.cancelDelete();
                this.isLoaded = true;
            }
        } finally {
            this.changeLoading(false);
        }
    }
    startDelete(unload: UnloadSettingsStorage) {
        if (unload.isNew) {
            this.unloads.length = this.unloads.length - 1;
            this.currentUnload = this.unloads[0];
            return;
        }
        this.deletingUnload = unload;
    }
    cancelDelete() {
        this.deletingUnload = undefined;
    }
    selectUnload(unload: UnloadSettingsStorage) {
        this.currentUnload = unload;
    }

    startCreatingUnload() {
        const newUnload = new UnloadSettingsStorage('NewFile');
        this.unloads.push(newUnload);
        this.selectUnload(newUnload);
    }

    get hasNew(): boolean {
        return this.unloads.some(x => x.isNew);
    }

    private changeLoading(value: boolean) {
        this.loading = value;
    }
}

function mapUnloadSettings(u: UnloadSettingsDto): UnloadSettingsStorage {
    const fieldValues = u.fieldValues.map(fv => new FieldValue(fv.fieldId, fv.id, fv.value));
    const stores = u.stores.map(s => new UnloadStore(s.storeId, s.isActive, s.id));
    return new UnloadSettingsStorage(u.name, u.id, fieldValues, stores, u.priceFieldId, u.descriptionFieldId, u.markerFieldId)
}

const unloadsSettingsStorage = new UnloadsSettingsStorage();
export default unloadsSettingsStorage;
