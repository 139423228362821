import {Embed} from "semantic-ui-react";
import React from "react";
import WelcomeMessage from "./WelcomeMessage";

export function WelcomeScreen() {
    return (
        <>
            <WelcomeMessage/>
        </>
    )
}