import {observable} from "mobx";
import api from "../../Services/Api";

class VariantWidgetStore {
    @observable variantId?: string;
    @observable contextKey?: string;
    @observable imageUrls?: string;
    @observable avitoId?: number;
    @observable isLoading: boolean = false;

    public async init(contextKey: string, variantId: string) {
        this.contextKey = contextKey;
        this.setIsLoading(true);
        try {
            const authSuccess = await api.auth(contextKey);
            if (authSuccess) {
                this.variantId = variantId;
                await this.load();
            }
        } finally {
            this.setIsLoading(false);
        }
    }

    private async load() {
        if (!this.variantId) {
            return;
        }

        const result = await api.get('variant/' + this.variantId);
        if (result.success) {
            this.setImageUrls(result.data.imageUrls);
            this.setAvitoId(result.data.avitoId);
        }
    }

    public async save() {
        if (!this.variantId) {
            return;
        }

        const result = await api.post('variant/save', {
            id: this.variantId,
            imageUrls: this.imageUrls,
            avitoId: this.avitoId
        });
        
        if (result.success) {
            this.setImageUrls(result.data.imageUrls);
            this.setAvitoId(result.data.avitoId);
        }
    }

    public cleanup() {
        this.variantId = undefined;
        this.contextKey = undefined;
        this.imageUrls = undefined;
    }

    public setImageUrls(urls: string) {
        this.imageUrls = urls;
    }
    
    public setAvitoId(avitoId?: number) {
        this.avitoId = avitoId;
    }

    private setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
}

const variantWidgetStore = new VariantWidgetStore();
export default variantWidgetStore;